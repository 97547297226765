<template>

  <div class="form-compact form-compact-job-publication">
    <v-dialog
      :value="showDialogChannelSubscription"
      width="580px"
      persistent
      overlay-color="transparent"
      :eager="true"
    >
      <v-card max-height="100vh" v-if="channel_subscription">
        <div>
          <v-system-bar dark color="primary">
            <v-icon class="mr-1">mdi-plus</v-icon>
            <small><strong class="text-uppercase">{{$t('COMMON.ADD')}}</strong></small>
            <v-spacer/>
            <v-icon
              small
              class="clickable"
              @click="closeDialogChannelSubscription"
            >
              mdi-close
            </v-icon>
          </v-system-bar>

          <v-toolbar
            color="transparent"
            dense
            flat
            height="60px"
          >
            <div><v-img :src="getImage(channel_subscription.channel)" max-width="120px" contain /></div>
            <!-- <v-toolbar-title>{{$t('JOB.FORM_CHANNEL_SUBSCRIPTION_PARAMS_TITLE')}}</v-toolbar-title> -->
            <v-spacer />
            <v-btn
              x-small
              fab
              depressed
              color="error"
              class="mr-4"
              @click="deletePublications"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              rounded
              depressed
              color="success"
              :disabled="!valid"
              @click="applyPublications"
            >
              <v-icon class="mr-2">mdi-check</v-icon>
              {{$t('COMMON.APPLY')}}
            </v-btn>
          </v-toolbar>
          <div class="toolbar-separator primary ml-4 mr-4"/>
        </div>

        <div class="pa-6" v-if="channel_subscription && channel_subscription.methods && channel_subscription.methods.length > 0">
          <p class="primary--text">{{$t('JOB.FORM_CHANNEL_SUBSCRIPTION_PARAMS_CHOOSE_PRICING_MODEL')}}</p>
          <div class="ma-0 d-flex" v-if="channel_subscription.methods.indexOf('organic') !== -1">
            <v-col class="pa-0" cols="3">
              <v-checkbox class="mt-1"
              v-model="method"
              value="organic"
              label="Organic"
              />
            </v-col>
            <v-col cols="6"></v-col>
            <v-col class="pa-0" cols="2">
              <v-chip outlined
              class="mt-1"
              color="grey"
              label>
              {{$t('COMMON.FREE')}}
            </v-chip>
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <v-input type="hidden"
              v-model="prices.organic"
              :value="0"
              />
            </v-col>
          </div>
          <div class="ma-0 d-flex" v-if="channel_subscription.methods.indexOf('credit') !== -1">
            <v-col class="pa-0" cols="3">
              <v-checkbox class="mt-1"
              v-model="method"
              value="credit"
              label="Crédits"
              />
            </v-col>
            <v-col cols="6"></v-col>
            <v-col class="pa-0" cols="2">
              <v-chip outlined class="mt-1" color="grey" label>{{$t('COMMON.FIXED')}}</v-chip>
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <v-input type="hidden"
              v-model="prices.credit"
              :value="1"
              />
            </v-col>
          </div>
          <div class="ma-0 d-flex" v-if="channel_subscription.methods.indexOf('cpa') !== -1">
            <v-col class="pa-0" cols="3">
              <v-checkbox class="mt-1"
              v-model="method"
              value="cpa"
              label="CPA"
              />
            </v-col>
            <v-col cols="6"></v-col>
            <template v-if="channel_subscription.methods.indexOf('cpa') !== -1" >
              <v-col class="pa-0" cols="2">
                <v-text-field
                  v-model="prices.cpa"
                  :label="$t('COMMON.PRICE')"
                  type="number"
                  step="0.01"
                  min="0.01"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col class="pl-2 pa-0" cols="1">
                <v-btn icon @click="prices = Object.assign({}, prices, {cpa:channel_subscription.channel.prices.cpa || 0})">
                  <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
              </v-col>
            </template>
          </div>
          <div class="ma-0 d-flex" v-if="channel_subscription.methods.indexOf('cpc') !== -1">
            <v-col class="pa-0" cols="3">
              <v-checkbox class="mt-1"
              v-model="method"
              value="cpc"
              label="CPC"
              />
            </v-col>
            <v-col cols="6"></v-col>
            <template v-if="channel_subscription.methods.indexOf('cpc') !== -1" >
              <v-col class="pa-0" cols="2">
                <v-text-field
                  v-model="prices.cpc"
                  :label="$t('COMMON.PRICE')"
                  type="number"
                  step="0.01"
                  min="0.01"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col class="pl-2 pa-0" cols="1">
                <v-btn icon @click="prices = Object.assign({}, prices, {cpc:channel_subscription.channel.prices.cpa || 0})">
                  <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
              </v-col>
            </template>
          </div>
          <div class="ma-0 d-flex" v-if="channel_subscription.methods.indexOf('cpm') !== -1">
            <v-col class="pa-0" cols="3">
              <v-checkbox class="mt-1"
              v-model="method"
              value="cpm"
              label="CPM"
              />
            </v-col>
            <template v-if="channel_subscription.methods.indexOf('cpm') !== -1" >
              <v-col class="pa-0" cols="2">
                <v-text-field
                  v-model="prices.cpm"
                  :label="$t('COMMON.PRICE')"
                  type="number"
                  step="0.01"
                  min="0.01"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col class="pl-2 pa-0" cols="1">
                <v-btn icon @click="prices = Object.assign({}, prices, {cpm:channel_subscription.channel.prices.cpm || 0})">
                  <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
              </v-col>
            </template>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <div class="form-compact-header">
      <v-system-bar dark color="primary">
        <v-icon class="mr-1">{{ $t('JOB.ICON') }}</v-icon>

        <small><strong class="text-uppercase">{{ $t('JOB.FORM_TITLE_PUBLISH') }}</strong></small>

        <v-spacer/>
        <v-icon
          small
          class="clickable"
          @click="$emit('close')"
        >
          mdi-close
        </v-icon>
      </v-system-bar>

      <v-toolbar
        color="transparent"
        flat
        height="66px"
      >
        <v-toolbar-title class="primary--text">
          {{ $tc('JOB.FORM_TITLE_PUBLISH_COUNT', ids.length) }}
        </v-toolbar-title>
        <v-spacer />
        <v-tooltip bottom transition="none">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              depressed
              :disabled="channelSubscriptionItems.filter(v => !!v.publication).length === 0"
              color="success"
              @click="saveItem"
            >
            <!-- :disabled="!valid" -->
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </template>
          {{ $t('COMMON.APPLY') }}
        </v-tooltip>
      </v-toolbar>
      <div class="toolbar-separator primary ml-4 mr-4"/>

    </div>

    <div :class="['form-compact-content', blocked ? 'blocked' : '']">
      <v-overlay
        :value="blocked"
        color="white"
        :opacity="0.75"
        absolute
      >
        <div class="text-center">
          <div class="black--text">
            <v-progress-circular
              indeterminate
              color="accent"
            ></v-progress-circular>
            <p class="mt-4 mb-0">Création des publications</p>
            <p class="grey--text">{{ this.nb_passing + ' / ' + this.nb_total + ' pour ' + this.nb_channel}}</p>
          </div>

          {{loading_save}}
          <!--
          <p class="black--text">{{ $t(loaded_values.status_code) }}</p>
          <div class="mt-4">
            <v-btn
              depressed
              small
              color="grey"
              @click="loadItem"
            >
              {{ $t('COMMON.RELOAD') }}
            </v-btn>
          </div> -->
        </div>
      </v-overlay>
      <Loading :value="!!loading" />
      <v-container fluid class="pt-4 pb-10">
        <v-form v-model="valid">

          <div class="pr-6 pl-6">
            <!-- <v-alert
              outlined
              type="success"
              icon="mdi-content-duplicate"
              dense
              v-if="this.loaded_values && this.loaded_values.parent_id"
            >
              {{ $t('JOB.HAS_PARENT') }}
              <br >
              <a target="blank" :href="`/jobs/?edit=${this.loaded_values.parent_id}`">
                {{ $t('JOB.HAS_PARENT_BTN') }}
              </a>
            </v-alert>
            <FormJobCompactIntro @input="handleInput" :values="values" /> -->
          </div>

          <div class="pr-6 pl-6 mt-4" v-if="hideCampaign">

            <v-row class="mt-0">
              <v-col cols="7" class="pt-0 pb-7">
                <p class="primary--text">{{ $t('JOB.FORM_CAMPAIGN_CHOOSE_TITLE') }}</p>
                <!-- item-value="id" -->
                <!-- :label="$t('JOB.FIELD_CAMPAIGN')" -->
                <v-select
                  v-model="campaign"
                  :items="campaignItems"
                  item-text="title"
                  return-object
                  required
                  hide-details
                  :placeholder="$t('JOB.FIELD_CAMPAIGN_PLACEHOLDER')"
                  outlined
                  dense
                >
                <!-- 321:69  error    'active' is defined but never used  vue/no-unused-vars -->
                <!-- <template v-slot:item="{ active, item, attrs, on }"> -->
                  <!-- <v-list-item v-on="on" v-bind="attrs" #default="{ active }"> -->
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" >
                    <v-list-item-content>
                      <v-list-item-title><span>{{item.title}}</span></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <status-label class="ml-2" x-small :item="item" />
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-select>
              </v-col>
              <v-col cols="4">
                <p class="pa-0 mb-2">&nbsp;</p>
                <v-btn
                  small
                  depressed
                  color="success"
                  elevation="2"
                  @click="showDialogAddCampaign = true"
                >
                  {{$t('CREATIVE.FORM_TITLE_NEW')}}
                </v-btn>
              </v-col>
            </v-row>

            <template v-if="campaign">
              <v-row class="mt-0 mb-0 text-medium">
                <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
                  {{$t('JOB.FIELD_CAMPAIGN_TITLE')}}
                </v-col>
                <v-col cols="9" class="text-ellipsis text-nowrap pb-0">
                  <div class="d-flex"><span>{{campaign.title}}</span><status-label small :item="campaign" class="ml-4"></status-label></div>
                </v-col>
              </v-row>
              <v-divider />
              <v-row class="mt-0 mb-0 text-medium">
                <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
                  {{$t('JOB.FIELD_CAMPAIGN_START_DATE')}}
                </v-col>
                <v-col cols="9" class="text-ellipsis text-nowrap">
                  <span>{{new Date(campaign.start_date).toLocaleDateString()}}</span><small class="ml-4 grey--text">{{$moment(campaign.start_date).fromNow()}}</small>
                </v-col>
              </v-row>
              <v-divider />
              <v-row class="mt-0 mb-0 text-medium">
                <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
                  {{$t('JOB.FIELD_CAMPAIGN_END_DATE')}}
                </v-col>
                <v-col cols="9" class="text-ellipsis text-nowrap">
                  <span>{{new Date(campaign.end_date).toLocaleDateString()}}</span><small class="ml-4 grey--text">{{$moment(campaign.end_date).fromNow()}}</small>
                </v-col>
              </v-row>
            </template>
          </div>

          <div class="pr-6 pl-6 mt-10">
            <p class="primary--text mb-0">{{ $t('JOB.FORM_CHANNEL_SUBSCRIPTION_CHOOSE_TITLE') }}</p>
            <p><small class="grey--text">{{$t('JOB.FORM_CHANNEL_SUBSCRIPTION_CHOOSE_TEXT')}}</small></p>
            <v-row>
              <template v-for="(item, i) in channelSubscriptionItems">
                <v-col cols="3" :key="'cha'+i">
                  <div :class="['selection-box rounded pa-4', item.publication ? 'bordered-selected elevation-4' : 'bordered']" @click="editChannelSubscription(item)">
                    <v-img :src="getImage(item.channel)" max-height="16px" contain />
                    <p class="text-nowrap text-ellipsis ma-0 mt-2 text-small">{{item.channel.title}}</p>

                    <template v-if="item.publication">
                      <v-icon class="corner-icon elevation-2" color="success">mdi-check-circle</v-icon>
                      <v-chip class="mt-4" outlined label color="success">
                        <span v-if="item.publication.method === 'credit'">1 credit</span>
                        <span v-else-if="item.publication.method === 'Organic'">0.00 €</span>
                        <span v-else>{{+(item.publication.prices[item.publication.method])}} €</span>
                      </v-chip>
                      <v-chip x-small label class="mt-2" color="success lighten-5 success--text">{{item.publication.method}}</v-chip>
                    </template>
                    <template v-else>
                      <v-btn depressed color="grey lighten-2" class="mt-4" fab>
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>

                  </div>
                </v-col>
              </template>
            </v-row>
          </div>
        </v-form>
      </v-container>
    </div>
    <DialogAddCampaign
      :value="showDialogAddCampaign"
      @close="showDialogAddCampaign = false"
      @load="loadCampaignItems()"
    />
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import StatusLabel from '@/components/StatusLabel'
import DialogAddCampaign from '@/components/DialogAddCampaign'

export default {
  name: 'FormPublicationCreativeCompact',
  data: () => ({
    key:0,
    blocked: false,
    nb_total: 0,
    nb_passing: 0,
    nb_channel: '',
    valid: false,
    loading: false,
    loading_save: false,
    prices: {},
    method: undefined,
    campaignItems: [],
    campaign: undefined,
    channel_subscription: undefined,
    showDialogChannelSubscription: false,
    showDialogAddCampaign: false,
    channelSubscriptionItems: [],
    publications: [],
  }),
  components: {
    Loading,
    StatusLabel,
    DialogAddCampaign,
  },
  props: {
    ids: {
      type: Array,
      default: () => ([])
    },
    props: {
      type: [Object, Boolean],
      default: false
    },
  },
  computed: {
    hideCampaign () {
      return !this.props.campaign_id
    },
    createCampaignJobs () {
      return this.props.createCampaignJobs
    }
  },
  watch: {
    campaign () {
      this.loadChannelSubscriptionItems()
    }
  },
  created () {
    this.loadCampaignItems()
  },
  methods: {

    publicationExists (channel_subscription_id) {
      for (let i = 0, len = this.channelSubscriptionItems.length; i < len; i++) {
        if (this.channelSubscriptionItems.id === channel_subscription_id && this.channelSubscriptionItems.publication) {
          return true
        }
      }
      return false
    },

    disabledPrice (method) {
      return ['organic'].indexOf(method) !== -1
    },

    showPrice (method) {
      return ['cpm', 'cpc', 'cpa', 'credit'].indexOf(method) !== -1
    },

    resetPrice (method) {
      const item = this.item
      this.key++
      this.prices[method] = this.getPrice(method)
    },

    getEstimation (method) {
      const channel_subscription = this.channel_subscription
      if (['credit', 'organic'].indexOf(method) !== -1) return 0
      return Math.floor(
        ((channel_subscription.prices && typeof channel_subscription.prices[method] === 'number' ? channel_subscription.prices[method] : null)
        || (channel_subscription.channel.prices && typeof channel_subscription.channel.prices[method] === 'number' ? channel_subscription.channel.prices[method] : null)
        || 0)
        *100)/100
    },

    getImage (item, size = "original") {
      const url = `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + item.controller_name.toLowerCase()  }.png`
    },

    loadCampaignItems () {
      this.loading = true
      this.$services.api_programmatic.campaign.search({ where: {status:[1]} })
        .then(response => {
          if (response.data) {
            this.campaignItems = response.data
            if (this.props.campaign_id) {
              for (let i = 0, len = this.campaignItems.length; i < len; i++) {
                if (this.campaignItems[i].id === this.props.campaign_id) {
                  this.campaign = this.campaignItems[i]
                  return this.loadChannelSubscriptionItems()
                }
              }
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadChannelSubscriptionItems () {
      if (this.campaign) {
        this.loading = true
        this.$services.api_programmatic.channel_subscription.search({ where: {methods: this.campaign.methods, status:[1], channel: true} })
        .then(response => {
          if (response.data) {
            this.channelSubscriptionItems = response.data
          }
        })
        .finally(() => {
          this.loading = false
        })
      }
    },

    saveItem () {
      const channel_subscriptions = this.channelSubscriptionItems.filter(v => !!v.publication)
      const campaign_id = this.campaign.id
      let ids = [...this.ids]
      this.nb_total = ids.length
      this.nb_passing = 0

      const insert = async (job_ids, channel_subscription, create_campaign_jobs, set_need_refresh) => {

        if (this.createCampaignJobs && create_campaign_jobs) {
          const campaignJobToCreate = []
          for (const job_id of job_ids) {
            campaignJobToCreate.push({
              job_id: job_id,
              campaign_id: campaign_id
            })
          }
          try {
            // Manual publications should not trigger the campaign rules, because we could end up with duplicates (race condition)
            await this.$services.api_programmatic.campaign_job.create({values:campaignJobToCreate})
          } catch (e) {
            console.error(e)
          }
        }

        const publicationToCreate = []

        const method = channel_subscription.publication.method
        const prices = channel_subscription.publication.prices
        const price = prices[method] || 0
        for (let i = 0, len = job_ids.length; i < len; i++) {
          // We set the need_refresh value only for the last_job of the last_chunk, to avoid partial publications
          // This is also set to yesterday, to gain priority in front of periodic feed updates
          const newPublication = {
            status: 1,
            campaign_id: campaign_id,
            job_id: job_ids[i],
            channel_id: channel_subscription.channel.id,
            channel_subscription_id: channel_subscription.id,
            creative_id: this.creative_id,
            price: price || 0,
            method: method,
            type: 'offer',
            refresh_interval: 0,
            need_refresh: set_need_refresh ? new Date(Date.now() - 24 * 60 * 60 * 1000) : null,
          }

          publicationToCreate.push(newPublication)
        }

        try {
          await this.$services.api_programmatic.publication.createBulk({values:publicationToCreate})
        } catch (e) {
          console.error(e)
        }
        this.nb_passing += job_ids.length
        this.nb_channel = channel_subscription.channel.title
      }

      const LIMIT_CALL_ITEMS = 100
      const loop = (create_campaign_jobs) => {
        this.blocked = true
        this.loading_save = true
        if (ids.length > 0 && channel_subscriptions.length > 0) {
          const job_ids = ids.splice(0, LIMIT_CALL_ITEMS)
          insert(job_ids, channel_subscriptions[0], create_campaign_jobs, ids.length === 0)
            .then(() => {
              loop(create_campaign_jobs)
            })
            .catch(e => {
              this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
            })
        } else if (channel_subscriptions.length > 0) {
          channel_subscriptions.shift()
          ids = [...this.ids]
          this.nb_passing = 0
          loop(false)
        } else {
          this.blocked = false
          this.loading_save = false
          this.$emit('update')
          this.$emit('close')
        }
      }

      loop(true)
    },

    editChannelSubscription (item) {
      this.channel_subscription = item
      if (!this.channel_subscription.publication) {
        this.prices = Object.assign({organic: 0, cpm:0.30, cpc:0.30, cpa:0.50, credit:1}, this.$clone(this.channel_subscription.prices || {})),
        this.method = this.channel_subscription.methods[0]
      } else {
        this.prices = this.$clone(this.channel_subscription.publication.prices),
        this.method = this.channel_subscription.publication.method
      }
      this.showDialogChannelSubscription = true
    },

    closeDialogChannelSubscription() {
      this.showDialogChannelSubscription = false
      this.channel_subscription = undefined
    },

    applyPublications () {
      for (let i = 0, len = this.channelSubscriptionItems.length; i < len; i++) {
        if (this.channelSubscriptionItems[i].id === this.channel_subscription.id) {
          this.channelSubscriptionItems[i].publication = {
            prices: this.$clone(this.prices),
            method: this.method,
          }
          // force rerender
          this.key++
          break
        }
      }
      this.showDialogChannelSubscription = false
    },

    deletePublications () {
      for (let i = 0, len = this.channelSubscriptionItems.length; i < len; i++) {
        if (this.channelSubscriptionItems[i].id === this.channel_subscription.id) {
          delete this.channelSubscriptionItems[i].publication
          // force rerender
          this.key++
          break
        }
      }
      this.showDialogChannelSubscription = false
    },
  }
}
</script>
